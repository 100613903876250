
import { things } from "@/network/lookUp.ts";
import {
  computed,
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  defineProps,
  defineEmit,

} from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const router = useRouter();

    const visible = computed(
      {
        get: () => {
         // console.log(props.modelValue);
          return props.modelValue;
        },
        set: (v) => {
          emit("update:modelValue", v);
        }
      }
    );

    const nextTo = (item) => {
      router.push({ name: "ReportAndReviewThingsManage", params: { id: 0, createCode: item.code + (item.applyType ? `-${item.applyType}` : '') } })
    };

    const spanMethod = ({ row, column, rowIndex, columnIndex }) => {
      if (row.rowSpan !== undefined && columnIndex < 3) {
        return {
          rowspan: row.rowSpan,
          colspan: 1,
        };
      }
    };

    return {
      visible,
      nextTo,
      spanMethod,
      things
    }
  }
})

