<template>
  <el-dialog v-model="visible" :title="`文件下载 - ${title}`">
    <el-form :model="attaches" label-width="200px">
      <el-form-item
        :label="attachSetting.find(p => p.id == i.type)?.label"
        v-for="(i) in attaches"
        :key="i.filePath"
      >
        <a :href="i.filePath" target="_blank" :download="i.fileName">
          <i class="el-icon-download"></i>
          {{ i.fileName }}
        </a>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-download" @click="allDownload">打包下载</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { openWindowWithToken } from "@/utils/util.js"
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  PropType,
  computed,
} from "vue";
import { attachSetting } from "@/network/lookUp.ts";
export default defineComponent({
  components: {

  },
  props: {
    setting: { type: Object },

  },
  emits: ["change", "submit"],
  setup(props, { emit }) {


    const { visible, id, attaches, title } = toRefs(props.setting as any);
    const allDownload = () => {
      openWindowWithToken(`/api/thingApply/downloadAll/${id.value}`);
    }

    return {
      attachSetting,
      attaches,
      allDownload,
      title,
      visible
    };
  },
});
</script>

<style scoped>
</style>