<template>
  <Base title="审核事项" titleIcon="el-icon-s-claim">
  <div>
    <div class="search-row">
      <el-space>
        <el-button type="primary" @click="selectThingVisible = true" icon="el-icon-plus"
          v-if="user.inRoles(roles.applyUnit)">申请
        </el-button>

        <el-select placeholder="状态" v-model="filter.status" clearable v-show="activeName == 'all'">
          <el-option :value="i" :label="item" v-for="(item, i) in thingApplyStatusText" :key="i"></el-option>
        </el-select>
        <el-select v-model="filter.city" placeholder="市级" clearable :disabled="user.inRoles(roles.city)"
          v-show="user.inRoles(roles.prov)">
          <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="filter.town" placeholder="区县" clearable v-if="user.inRoles(roles.city, roles.prov)">
          <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <el-input placeholder="名称" v-model="filter.keyword" style="width: 500px">
          <template #prepend>关键字</template>
        </el-input>
        <el-button type="primary" style="margin-left: 5px" @click="
          () => {
            filter.pageNum = 1;
            query();
          }
        ">查询</el-button>
      </el-space>
    </div>
  </div>
  <el-tabs v-model="activeName" type="card" @tab-click="() => {
  
    if (activeName != -2) {
  
      filter.status = activeName == 'all' ? null : parseInt(activeName); filter.pageNum = 1; query();
    }
  }">
    <el-tab-pane label="草稿" name="0" v-if="!user.inRoles(roles.prov)"></el-tab-pane>
    <el-tab-pane label="初级审核" name="1" v-if="user.inRoles(roles.city)"></el-tab-pane>
    <!-- <el-tab-pane label="汇总申请" :name="-2" v-if="user.inRoles(roles.city)"></el-tab-pane> -->
    <el-tab-pane label="省级审核" name="2" v-if="user.inRoles(roles.prov)"></el-tab-pane>
    <el-tab-pane label="全部" name="all"></el-tab-pane>
  </el-tabs>

  <template v-if="activeName != '-2'">
    <el-table :data="pagerData.list" v-loading="isLoading">
      <el-table-column label="地区" prop="areaName"></el-table-column>
      <el-table-column label="文保单位" prop="heritageUnitName"></el-table-column>
      <el-table-column label="事项编码" prop="applyCode" width="100"></el-table-column>
      <el-table-column label="事项名称" prop="thingName" width="140" align="center">
        <template #default="{ row: item }">
          {{
            things.find(p => p.code == item.applyCode)?.thingsName + (item.ApplyType ?
              `(${things.find(p => p.applyType == item.applyType)?.applyTypeName})` : '')
          }}
        </template>
      </el-table-column>

      <el-table-column label="申请文件标题" prop="applyFileTitle">
        <template #default="{ row: item }">
          <a href="javascript:void(0)" @click="$router.push({ name: 'ReportAndReviewThingsDetail', params: { id: item.id } })">{{
              item.applyFileTitle
            }}</a>

        </template>
      </el-table-column>
      <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
      <el-table-column label="申请单位" prop="applyPerson" align="center"></el-table-column>
      <el-table-column label="经办人" prop="doPerson" align="center"></el-table-column>
      <el-table-column label="状态" prop="status" width="100">
        <template #default="{ row: item }">{{ thingApplyStatusText[item.status] }}</template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="{ row: item }">

          <el-button type="text" icon="el-icon-edit"
            @click="$router.push({ name: 'ReportAndReviewThingsManage', params: { id: item.id } })" size="small"
            v-if="item.status == 0">
            填报
          </el-button>
          <el-popconfirm title="确认删除吗！？" @confirm="remove(item.id)" placement="top" v-if="item.status == 0">
            <template #reference>
              <el-button type="text" icon="el-icon-delete" size="small">删除</el-button>
            </template>
          </el-popconfirm>

          <!-- <el-button
              type="danger"
              icon="el-icon-refresh-left"
              size="small"
              v-if="item.status > 0 && ((item.status == 2 && user.inRoles(roles.prov)) || (item.status == 1 && user.inRoles(roles.city)))"
              @click="openCancel(item)"
              ></el-button>-->

          <!-- <el-popconfirm
              title="确认退回吗！？"
              @confirm="back(item.id)"
              placement="top"
            
            >
              <template #reference>
                <el-button type="danger" icon="el-icon-refresh-left" size="small"></el-button>
              </template>
              </el-popconfirm>-->
          <el-button type="text" icon="el-icon-check"
            @click="$router.push({ name: 'ReportAndReviewThingsManage', params: { id: item.id } })" size="small"
            v-if="((item.status == 2 && user.inRoles(roles.prov)) || (item.status == 1 && user.inRoles(roles.city)))">
            审核
          </el-button>
          <el-button type="text" icon="el-icon-download" @click="download(item)" size="small">下载</el-button>
          <el-button type="text" icon="el-icon-copy-document" @click="doQueryHistory(item.id)" size="small">日志
          </el-button>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="padding-left: 5px" @current-change="handleCurrentChange" @size-change="handleSizeChange"
      v-model:page-size="filter.pageSize" :total="pagerData.count" layout="sizes,total, prev, pager, next"
      v-model:currentPage="filter.pageNum"></el-pagination>
  </template>
  <template></template>

  <el-dialog title="操作日志" @close="logs = []" v-model="showLogHistory">
    <LogHistory :data="logs"></LogHistory>
  </el-dialog>

  <SelectThing v-model="selectThingVisible" v-if="selectThingVisible"></SelectThing>
  <Attach :setting="attachSetting" v-if="attachSetting.visible"></Attach>
  </Base>
</template>

<script lang="ts">
import LogHistory from "@/views/shared/logHistory.vue";
import { ElMessageBox } from 'element-plus'
import roles from "@/types/roles.ts";
import Attach from "./attach.vue";
import SelectThing from "./selectThing.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import { useQuery, useThingApplyAction, useQueryActionHistory } from "@/network/thingApply";
import { dicLabel, things, thingApplyStatusText } from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base,  Attach, SelectThing, LogHistory },
  setup() {
    const user: any = inject("user");
    const activeName = ref(null);

    const selectThingVisible = ref(false);
    const attachSetting = reactive({
      visible: false,
      id: null,
      attaches: [],
      title: ''
    });


    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null
    });
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryActionHistory();
    const [isLoading, pagerData, query] = useQuery(filter);
    const [isProcessing, backFn, removeFn] = useThingApplyAction();


    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };

    const download = (item) => {
      attachSetting.id = item.id;
      attachSetting.attaches = item.attachList;
      attachSetting.visible = true;
      attachSetting.title = item.applyFileTitle;
    }
    const remove = (id) => {
      removeFn(id).then(() => {
        query();
      })
    }
    const back = (id, value) => {
      backFn(id, value).then(() => {
        query();
      })
    }


    const openCancel = (item) => {
      ElMessageBox.prompt('请输入退回原因！', '退回', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea'

      })
        .then(({ value }) => {
          back(item.id, value);
        })

    }
    onMounted(() => {
      activeName.value = "0";
      filter.status = 0;
      if (user.inRoles(roles.city)) {
        filter.status = 1;
        activeName.value = "1";
        filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   filter.city = user.city_ID;
        //   stop();
        // });
      }
      if (user.inRoles(roles.prov)) {
        filter.status = 2;
        activeName.value = "2";
      }
      query();

      //console.log(activeName.value);
    });
    return {
      showLogHistory,
      logs,
      doQueryHistory,
      selectThingVisible,
      activeName,
      thingApplyStatusText,
      isProcessing,
      back,
      dicCity,
      dicTown,
      things,
      download,
      remove,
      dicLabel,
      isLoading,
      user,
      roles,
      attachSetting,
      query,
      pagerData,
      filter,
      openCancel,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };

  },
});
</script>

<style scoped>

</style>

