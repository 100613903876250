
import { openWindowWithToken } from "@/utils/util.js"
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  PropType,
  computed,
} from "vue";
import { attachSetting } from "@/network/lookUp.ts";
export default defineComponent({
  components: {

  },
  props: {
    setting: { type: Object },

  },
  emits: ["change", "submit"],
  setup(props, { emit }) {


    const { visible, id, attaches, title } = toRefs(props.setting as any);
    const allDownload = () => {
      openWindowWithToken(`/api/thingApply/downloadAll/${id.value}`);
    }

    return {
      attachSetting,
      attaches,
      allDownload,
      title,
      visible
    };
  },
});
