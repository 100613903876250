<template>
  <el-dialog v-model="visible" title="选择申报事项" width="80%">
    <el-table :data="things" :span-method="spanMethod">
      <el-table-column label="事项编码" prop="code" width="100" align="center"></el-table-column>
      <el-table-column label="事项名称" prop="thingsName"></el-table-column>
      <el-table-column label="受理机构" prop="org"></el-table-column>
      <el-table-column label="办理时限" prop="timelimit"></el-table-column>
      <el-table-column label="申报主体" prop="applyUnit"></el-table-column>
      <el-table-column label="申报类型" prop="applyTypeName"></el-table-column>
      <el-table-column label="操作">
        <template #default="{ row: item }">
          <el-button type="primary" @click="nextTo(item)">申请</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script lang="ts">
import { things } from "@/network/lookUp.ts";
import {
  computed,
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  toRef,
  Ref,
  defineProps,
  defineEmit,

} from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const router = useRouter();

    const visible = computed(
      {
        get: () => {
         // console.log(props.modelValue);
          return props.modelValue;
        },
        set: (v) => {
          emit("update:modelValue", v);
        }
      }
    );

    const nextTo = (item) => {
      router.push({ name: "ReportAndReviewThingsManage", params: { id: 0, createCode: item.code + (item.applyType ? `-${item.applyType}` : '') } })
    };

    const spanMethod = ({ row, column, rowIndex, columnIndex }) => {
      if (row.rowSpan !== undefined && columnIndex < 3) {
        return {
          rowspan: row.rowSpan,
          colspan: 1,
        };
      }
    };

    return {
      visible,
      nextTo,
      spanMethod,
      things
    }
  }
})

</script>

<style></style>