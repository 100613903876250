
import LogHistory from "@/views/shared/logHistory.vue";
import { ElMessageBox } from 'element-plus'
import roles from "@/types/roles.ts";
import Attach from "./attach.vue";
import SelectThing from "./selectThing.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import { useQuery, useThingApplyAction, useQueryActionHistory } from "@/network/thingApply";
import { dicLabel, things, thingApplyStatusText } from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base,  Attach, SelectThing, LogHistory },
  setup() {
    const user: any = inject("user");
    const activeName = ref(null);

    const selectThingVisible = ref(false);
    const attachSetting = reactive({
      visible: false,
      id: null,
      attaches: [],
      title: ''
    });


    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null
    });
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryActionHistory();
    const [isLoading, pagerData, query] = useQuery(filter);
    const [isProcessing, backFn, removeFn] = useThingApplyAction();


    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };

    const download = (item) => {
      attachSetting.id = item.id;
      attachSetting.attaches = item.attachList;
      attachSetting.visible = true;
      attachSetting.title = item.applyFileTitle;
    }
    const remove = (id) => {
      removeFn(id).then(() => {
        query();
      })
    }
    const back = (id, value) => {
      backFn(id, value).then(() => {
        query();
      })
    }


    const openCancel = (item) => {
      ElMessageBox.prompt('请输入退回原因！', '退回', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'textarea'

      })
        .then(({ value }) => {
          back(item.id, value);
        })

    }
    onMounted(() => {
      activeName.value = "0";
      filter.status = 0;
      if (user.inRoles(roles.city)) {
        filter.status = 1;
        activeName.value = "1";
        filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   filter.city = user.city_ID;
        //   stop();
        // });
      }
      if (user.inRoles(roles.prov)) {
        filter.status = 2;
        activeName.value = "2";
      }
      query();

      //console.log(activeName.value);
    });
    return {
      showLogHistory,
      logs,
      doQueryHistory,
      selectThingVisible,
      activeName,
      thingApplyStatusText,
      isProcessing,
      back,
      dicCity,
      dicTown,
      things,
      download,
      remove,
      dicLabel,
      isLoading,
      user,
      roles,
      attachSetting,
      query,
      pagerData,
      filter,
      openCancel,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };

  },
});
